import React from "react"
import FeaturedRoom from "../Features/FeaturedRoom"
import useAllRoom from "../../hooks/use-all-room"

const RoomFeed = () => {
  const allRoom = useAllRoom()

  return allRoom.map((node, index) => {
    return <FeaturedRoom key={index} feature={node} />
  })
}

export default RoomFeed
